import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT, DecimalPipe } from '@angular/common';

import { environment } from '@environments/environment';

import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';

const baseUrl = `${environment.apiUrl }`;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public elem: any;
    public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;

    indicadores = {
        uf: '0',
        dolar: '0',
        utm: '0'
    };

    constructor(
        private http: HttpClient,
        public layout: LayoutService,
        public navServices: NavService,
        private decimalPipe: DecimalPipe,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    ngOnInit() {
        this.elem = document.documentElement;

        this.indicesEconomicos();
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
        this.navServices.megaMenu  = false;
        this.navServices.levelMenu = false;
    }

    layoutToggle() {
        this.dark = !this.dark;
        this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
    }

    languageToggle() {
        this.navServices.language = !this.navServices.language;
    }

    toggleFullScreen() {
        this.navServices.fullScreen = !this.navServices.fullScreen;
        if (this.navServices.fullScreen) {
            if (this.elem.requestFullscreen) {
                this.elem.requestFullscreen();
            } else if (this.elem.mozRequestFullScreen) {
                /* Firefox */
                this.elem.mozRequestFullScreen();
            } else if (this.elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.elem.webkitRequestFullscreen();
            } else if (this.elem.msRequestFullscreen) {
                /* IE/Edge */
                this.elem.msRequestFullscreen();
            }
        } else {
            if (!this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    indicesEconomicos() {
        this.http.get<any>(`${baseUrl}/inicio/indices-economicos`).subscribe(resp => {
            let indicadores = {
                uf: this.decimalPipe.transform(resp.data.uf, '1.2-2', 'es'),
                dolar: this.decimalPipe.transform(resp.data.dolar, '1.2-2', 'es'),
                utm: this.decimalPipe.transform(resp.data.utm, '1.2-2', 'es')
            };

            this.indicadores = indicadores;
        });
    }

}
